<template>
    <Layout>
        <div class="row">

            <div class="col-12">
                <h3>Vendas</h3>
            </div>

            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <FilterBase session="Sales" :filter="filter"/>
                    </div>
                </div>
            </div>

            <div class="col-md-6 v-step-0">
                <div class="card border-1 border-left-3 border-left-primary text-center">

                    <div class="card-body">
                        <h4 class="mb-0" v-if="sales || sales === 0">
                            {{ sales }}
                        </h4>
                        <b-skeleton type="button" v-else class="mx-auto"></b-skeleton>
                        <div>Vendas realizadas</div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="card border-1 border-left-3 border-left-primary text-center">

                    <div class="card-body">
                        <h4 class="mb-0" v-if="value || value === 0">{{ maskMoneyTable(value) }}</h4>
                        <b-skeleton type="button" v-else class="mx-auto"></b-skeleton>
                        <div>Valor líquido</div>
                    </div>

                </div>
            </div>
            <div class="col-12">
                <div class="card">
                    <div class="card-body">


                        <TableList session="Sales" url="vendas/" :table="table" :keys="keys">

                            <template #created="{ value }">
                                {{ convertDateText(value.created_at) }}
                            </template>

                            <template #mentoring="{ value }">
                                {{ value.mentoring.title }}
                            </template>

                            <template #mentee="{ value }">
                                {{ value.mentee.name }}
                            </template>

                            <template #status="{ value }">
                                <BBadge v-if="value.status === 1" variant="warning" class="fs-12">Pendente</BBadge>
                                <BBadge v-else-if="value.status === 2" variant="success" class="fs-12">Pago</BBadge>
                                <BBadge v-else-if="value.status === 3" variant="danger" class="fs-12">Negado</BBadge>
                            </template>

                            <template #value="{ value }">
                                {{ maskMoneyTable(value.price_discount) }}
                            </template>


                        </TableList>
                    </div>
                </div>
            </div>

        </div>
    </Layout>
</template>

<script>
import Layout from '@/components/layout/main.vue';
import TableList from "@/components/base/table-list.vue";
import {convertDateText, maskMoneyTable} from "@/components/composables/masks";
import {setSessions} from "@/components/composables/setSessions";
import FilterBase from "@/components/base/filter-component.vue";
import {mapState} from "vuex";
import http from "@/http";

export default {

    components: {
        FilterBase,
        Layout,
        TableList
    },

    data() {
        return {
            table: [
                {
                    column: 'Data',
                },
                {
                    column: 'Mentoria',
                },
                {
                    column: 'Cliente',
                },
                {
                    column: 'Status',
                },
                {
                    column: 'Valor Líquido',
                },
            ],
            keys: ['created', 'mentoring', 'mentee', 'status', 'value'],
            filter: {
                inputs: [
                    {
                        name: 'name',
                        label: 'Nome',
                        col: '4',
                        type: 'text'
                    },
                ],
                selects: [
                    {
                        name: 'mentoring_id',
                        label: 'Mentoria',
                        col: '4',
                        options: [
                            {
                                value: '',
                                name: 'Todas mentorias'
                            }
                        ]
                    }
                ]
            },
        }
    },

    methods: {
        convertDateText,
        maskMoneyTable,

        getMentorings() {
            http.get('mentorings?paginated=false&orderByDesc=title&status=1')
                .then(response => {
                    response.data.forEach(el => {
                        this.filter.selects[0].options.push({
                            value: el.id,
                            name: el.title
                        })
                    })
                })
        }

    },

    computed: {
        ...mapState({
            sales: state => state.api.listAll.sales,
            value: state => state.api.listAll.sum,
            discount: state => state.api.listAll.discount,
        })
    },

    mounted() {
        if (!localStorage.getItem('Sales')) setSessions('Sales');
        this.getMentorings()
    }
}

</script>
